import React, { Component } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Link as NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

import Logo from '../logo'

import { media, space, colour } from '../../utils/variables';
import mixin from '../../utils/mixins';

import './menu.css';

const burgerOpen = keyframes`
    50% {
        width: 100vw;
		height: 100vh;
		background-color: ${colour.white};
	}
    100% {
        bottom: 0;
		border-radius: 0;
		height: 100vh;
		width: 200px;
		background-color: ${colour.white};
    }
`;

const burgerClose = keyframes`
	0% {
		width: 3000px;
		height: 3000px;
	}
`;

const animationClose = () => css`
	animation: ${burgerClose} .2s both;
	transition: .2s;
`;

const animationOpen = () => css`
	animation: ${burgerOpen} .8s ease both;
`;

const burgerItem = css`
	background: ${colour.black}; 
	cursor: pointer;
	position: fixed;
	height: 5px;
	top: ${space.x3};
	right: ${space.x2};
	width: 30px;

	${mixin.layer( 'high' )}
`;

const MenuWrapper = styled.header`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 10000;

	${mixin.media( media.xtablet, `
		background-color: transparent;
		position: sticky;
		margin-top: -32px;
	` )}

	svg {
		margin-top: ${space.x1};
		height: ${space.x10};
		width: ${space.x10};
	}
`;

const MenuList = styled.ul`
	display: flex;
	flex-direction: column;	
	list-style: none;
	padding-left: 0;
	margin: 0;

	${mixin.media_by_range( 0, media.tablet, `
		${animationClose};

		height: 70px;
		right: 0;
		top: 0;
		padding-left: ${space.x3};
		padding-top: ${space.x8};
		position: sticky;
		width: 70px;

		${mixin.layer( 'mid' )}
	` )}

	${mixin.media( media.xtablet, `
		align-items: center;
		background-color: #ffe40c;
		flex-direction: row;
		justify-content: flex-end;
		position: initial;
		width: 100%;
	` )}
`;

const MenuItem = styled.li`
	display: none;
	margin: ${space.x2} 0;
	text-decoration: none;
	text-align: left;

	${mixin.media( media.xtablet, `
		display: block;
		margin: 0 ${space.x3};
		padding: ${space.x2};
	` )}

	a {
		cursor: pointer;
		color: ${colour.black};
		font-weight: 900;
		font-size: 18px;

		${mixin.media( media.xtablet, `
			color: ${colour.black};
		` )}

		&:hover {
			color: ${colour.white} !important;
			text-decoration: none !important;
		}
	}
`;

const BurgerToggle = styled.input`
	${burgerItem}
	${mixin.layer( 'max' )}

	background: none;
	height: 50px;
	top: ${space.x2};
	width: 50px;
	-webkit-appearance: none;

	${mixin.media( media.xtablet, `
		display: none;
	` )}

	&:focus {
		outline-width: 0;
	}

	&:checked + .burger-menu {
		top: 35px;
		transform: rotate(45deg);
		transition: transform .2s ease;

		&::after {
			content: none;
		}

		&::before {
			left: 0;
			top: 0;
			transform: rotate(-90deg);
			transition: transform .2s ease;
		}
	}

	&:checked + .burger-menu + div + .menu-list {
		${animationOpen}
	}

	&:checked + .burger-menu + div + .menu-list > li {
		display: block;
	}
`;

const Burger = styled.label`
	${burgerItem}

	&::before,
	&::after {
		${burgerItem}

		content: '';
		left: 0;
		position: absolute;
		top: -10px;
	}

	::after {
		top: 10px;
	}
	
	${mixin.media( media.xtablet, `
		display: none;
	` )}
`;

class Menu extends Component {
	renderMenuItems() {
		const elements = []

		const workshops = (
			<MenuItem key="workshops">
				<NavLink to="/workshops" replace>workshops</NavLink>
			</MenuItem>
		)

		const contact = (
			<MenuItem key="contact">
				<NavLink to="/contact" replace>contact</NavLink>
			</MenuItem>
		)

		const blog = (
			<MenuItem key="contact">
				<a href="https://wbm.melenie.co.uk/blog">blog</a>
			</MenuItem>
		)

		const login = (
			<MenuItem key="contact">
				<a href="https://wbm.melenie.co.uk/login">login</a>
			</MenuItem>
		)

		const instagram = (
				<MenuItem key="instagram" className="menu__social">
					<a href="https://instagram.com/melenie_codes" target="_blank" rel="noopener noreferrer">
						<FontAwesomeIcon icon={faInstagram} />
					</a>
				</MenuItem>
			)

		const facebook = (
				<MenuItem key="facebook" className="menu__social">
					<a href="https://www.facebook.com/WorkshopsByMelenie" target="_blank" rel="noopener noreferrer">
						<FontAwesomeIcon icon={faFacebook} />
					</a>
				</MenuItem>
			)

		const linkedin = (
				<MenuItem key="linkedin" className="menu__social">
					<a href="https://www.linkedin.com/in/melenie" target="_blank" rel="noopener noreferrer">
						<FontAwesomeIcon icon={faLinkedinIn} />
					</a>
				</MenuItem>
			)

		const melenie = (
				<MenuItem key="melenie" className="menu__first">
					<NavLink to="/" replace>Melenie</NavLink>
				</MenuItem>
			)

		elements.push(melenie)
		elements.push(workshops)
		elements.push(contact)
		elements.push(blog)
		elements.push(login)
		elements.push(instagram)
		elements.push(facebook)
		elements.push(linkedin)

		return elements;
	}

	render() {
		return (
			<MenuWrapper>
				<BurgerToggle type="checkbox" />
				<Burger className="burger-menu" />
                <Logo />
				<MenuList className="menu-list">{this.renderMenuItems()}</MenuList>
			</MenuWrapper>
		);
	}
}


export default Menu;
