import React from 'react'
import { Row, Col, Card, Button, Accordion } from 'react-bootstrap'

const EventCard = (props) => {
    const modules = props.event.modules.map((item, index) => {
        return (
            <div key={index} className="event__item">{item}</div>
        )
    })

    const moduleComponent = (modules) => {
        return (
            <Row>
                <Accordion className="event__modules">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0" className="event__modules_header">
                            <span role="img" aria-label="plus emoji">➕</span> Expand for Modules
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                {modules}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Row>
        )
    }


    return (
        <Col className="events__col" md={{ span: 6 }}>
            <Card className="events__card">
                <Row className="events__center">
                    <h4>{props.event.title}</h4>
                </Row>

                {/* Description */}
                <Row className="events__center events__description">
                    {props.event.description}
                </Row>

                {/* Level & Where */}
                <Row>
                    <Col>
                        <div><b>Level: </b></div>
                        <div>{props.event.level}</div>
                    </Col>
                    <Col>
                        <div><b>Where: </b></div>
                        <div>{props.event.where}</div>
                    </Col>
                </Row>

                {/* Duration */}
                <Row>
                    <Col>
                        <div><b>First Session: </b></div>
                        <div>{props.event.first}</div>
                    </Col>
                    <Col>
                        <div><b>{props.event.last ? 'Last Session' : 'Weekly Activities'}:</b></div>
                        <div>{props.event.last ? props.event.last : 'Included ✅' }</div>
                    </Col>
                </Row>
                {/* Divider */}
                <Row className="events__center events__divider">
                    <span><b>Your Investment </b><span role="img" aria-label="lightening emoji">⚡️</span></span>
                </Row>
                {/* Duration */}
                <Row>
                    <Col>
                        <div><b>Live Sessions: </b></div>
                        <div>{props.event.sessions}</div>
                    </Col>
                    <Col>
                        <div><b>Time each week: </b></div>
                        <div>{props.event.time}</div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div><b>Price: </b></div>
                        <div>{props.event.price}</div>
                    </Col>
                    <Col>
                        <div><b>Frequency: </b></div>
                        <div>{props.event.frequency}</div>
                    </Col>
                </Row>

                {/* Modules */}
                { props.event.modules.length > 0 ? moduleComponent(modules) : '' }

                {/* Book */}
                <Row>
                    <Col>
                    <Button 
                        className="events__btn"
                        size="lg" 
                        variant="primary"
                        href={props.event.link}
                        style={{marginTop:'16px'}}>
                            <b>{props.event.cta}</b>
                        </Button>
                    </Col>
                </Row>


            </Card>
        </Col>
    )
}

export default EventCard