
export const media = {
	small: '320px',
	mobile: '420px',
	tablet: '768px',
	xtablet: '1024px',
	desktop: '1280px',
	xdesktop: '1440px',
};

export const colour = {
	white: 'white',
	grey_22: '#222',
	grey_44: '#444',
	grey_dd: '#ddd',
	grey_ee: '#eee',
	grey_f6: '#f6f6f6',
	catsick: '#ffd56f',
	darkCatsick: '#FFBF23',
	yellow: '#ffe40c',
	black:  '#101820ff',
	green: '#00B2A9',
};

export const space = {
	x1: '8px',
	x2: '16px',
	x3: '24px',
	x4: '32px',
	x5: '40px',
	x6: '48px',
	x7: '56px',
	x8: '64px',
	x9: '72px',
	x10: '80px',
	x11: '88px',
};

export const font = {
	h1: `
		line-height: 1.4;
		font-weight: bold;
		font-size: 2rem;
		word-break: break-word;
		`,
	h2: `
		line-height: 1.4;
		font-weight: bold;
		font-size: 1.6rem;
		word-break: break-word;
		`,
	h3: `
		line-height: 1.2;
		font-weight: bold;
		font-size: 1.3rem;
		word-break: break-word;
		`,
	h4: `
		line-height: 1.2;
		font-weight: bold;
		font-size: 1.1rem;
		word-break: break-word;
	`,
};
