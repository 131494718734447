import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Router, Route } from 'react-router'
import { createBrowserHistory } from "history";

import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';

import Contact from './pages/contact'
import Workshops from './pages/workshops'
import App from './App'
import ScrollToTop from './components/scrollToTop'

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
      <ScrollToTop />
      <Route path="/contact" component={Contact} /> 
      <Route path="/workshops" component={Workshops} /> 
      <Route exact path="/" component={App} />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
