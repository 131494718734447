
import React from 'react'

import { Row, Col, Card, Container } from 'react-bootstrap'
import { Element } from 'react-scroll';

import Menu from '../../components/menu'
import Workshop from '../../components/workshop'
import Tiles from '../../components/tiles'
import Events from '../../components/events'
import Questions from '../../components/questions'
import Signup from '../../components/signup'
import Testimonial from '../../components/testimonial'
import TeamWorkshop from './teamWorkshop'
import Footer from '../../components/footer'

import questions from './questions'

const Workshops = () => {
    return (
        <div className="app">
            <Menu />
            <main className="app__content">
                <Workshop />
                <Tiles />

                <Row className="split">
                    <Col lg={{ span: 7 }} className="split__col">
                        <Testimonial />
                    </Col>
                    <Col  lg={{ span: 5 }} className="split__col">
                        <TeamWorkshop />
                    </Col>
                </Row>
                <Element id="events" name="events">
                    <Events />
                    <Container>
                        <Row>
                            <Col md={{ span: 6 }}>
                                <Card className="tiles__card">
                                    <Card.Body className="tiles__image">
                                        <img alt="Workshops by melenie course area" src="/assets/course_area.jpeg" />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={{ span: 6 }}>
                                <Card className="tiles__card">
                                    <Card.Body className="tiles__image tiles__image_bottom">
                                        <img alt="melenie presenting at a conference" src="/assets/ppig.jpeg" />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Element>
                <Element id="questions" name="questions">
                    <Questions questions={questions}/>
                </Element>
                <Element id="signup" name="signup">
                    <Signup />
                </Element>
                <Footer />
            </main>
        </div>
    )
}

export default Workshops