import React from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { media } from '../../../utils/variables';
import mixin from '../../../utils/mixins';

import Circle from '../../../assets/circle.svg';
import './teamWorkshop.css'

const TeamContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    text-align: left;

    ${mixin.media( media.tablet, `
        padding: 32px 64px;
    ` )}

    &::after {
        background-image: url(${( props ) => props.circle});
        background-repeat: repeat;
        background-size: 6px 6px;
        content: '';
        opacity: 0.75;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
`

const TeamWorkshop = () => {
    return (
        <TeamContainer circle={Circle}>
            <h4 className="team__subtitle">Interested in team workshops?</h4>
            <h3 className="team__title">Get in touch!</h3>
            <form className="team__form" action="https://formspree.io/mayplqka" method="POST">
                <input type="email" placeholder="Email" className="team__input" name="_replyto"/>
                <input type="text" placeholder="Name" className="team__input" name="name"/>
                <textarea placeholder="Message" className="team__input" name="message"/>
                
                <div className="g-recaptcha" data-sitekey="6LfX0LAZAAAAAMKB5gitu-_WdgNiyOZgrIeY4Bb6"></div>
                <Button 
                    className="team__button"
                    as="input" 
                    type="submit" 
                    value="Submit"
                    variant="primary"
                    name="submit" />
            </form>
        </TeamContainer>
    )
}

export default TeamWorkshop
