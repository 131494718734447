import React from 'react'
import { Container, Button } from 'react-bootstrap'

import './contactForm.css'

const ContactForm = () => {
    return (
        <Container>
            <h4>Have a question?</h4>
            <span>Have an idea for a collaboration, have a question about one of my workshops or just fancy grabbing a cuppa? Send me a message and I will get back to you asap!</span>
            <form
                action="https://formspree.io/mnqonddk"
                method="POST"
                className="contactForm__form"
                >
                <label>
                    Your name:
                    <input type="text" name="name" />
                </label>
                <label>
                    Your email:
                    <input type="text" name="_replyto" />
                </label>
                <label>
                    Website/Social:
                    <input type="text" name="socials" />
                </label>
                <label>
                    How did you hear about me?:
                    <input type="text" name="_hearabout" />
                </label>
                <label>
                    Your message:
                    <textarea name="message"></textarea>
                </label>

                <div class="contactForm__options">
                    <h5>What can I help with?</h5>
                    <label for="web">
                        <input type="checkbox" id="web" value="web" name="help" />
                        Web Development
                    </label>
                    <label for="tech">
                        <input type="checkbox" id="tech" value="web" name="help" />
                        General tech
                    </label>
                    <label for="code">
                        <input type="checkbox" id="code" value="code" name="help" />
                        Learning to code
                    </label>
                    <label for="education">
                        <input type="checkbox" id="education" value="education" name="help" />
                        Education
                    </label>
                    <label for="other">
                        <input type="checkbox" id="other" value="other" name="help" />
                        Other
                    </label>
                </div>
                <div className="g-recaptcha" data-sitekey="6LfX0LAZAAAAAMKB5gitu-_WdgNiyOZgrIeY4Bb6"></div>
                <Button 
                    type="submit"
                    size="lg"
                    variant="primary" 
                >Send</Button>
            </form>
        </Container>
    )
}

export default ContactForm