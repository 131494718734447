import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { Link } from 'react-scroll';

import './workshop.css'

const Workshop = () => {
    // const vimeoId = 448825229

    return (
        <Container className="workshop">
            <Row className="workshop__md">
                <Col md={{ span: 7 }} className="workshop__column">
                    <Card className="workshop__card">
                        <Card.Body>
                            <div className="workshop__image">
                                <iframe 
                                    title="introduction to coding with python"
                                    src="https://player.vimeo.com/video/448825229" 
                                    style={{ width: 100 + '%', height: 100 + '%' }}
                                    frameborder="0" 
                                    allow="autoplay; fullscreen" 
                                    allowfullscreen>
                                </iframe>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="workshop__content">
                    <h3>Learn how to code with Workshops By Melenie <span role="img" aria-label="person on computer emoji">👩‍💻</span></h3>
                    <p>I make writing code accessible to you and leave you with skills to create code snippets you can use in your day to day job</p>
                    <Link
                        offset={-60}
                        smooth
                        spy
                        hashSpy
                        isDynamic
                        duration={500}
                        activeClass="active"
                        to="events"
                    >
                        <Button 
                            size="lg" 
                            variant="primary">
                                Find Upcoming Workshops
                        </Button>
                    </Link><br/>
                    <i>Limited spaces available</i>
                </Col>
            </Row>
        </Container>
    )

}

export default Workshop