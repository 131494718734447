
import React, { Component } from 'react'
import { Container, Button, Col, Row, Card } from 'react-bootstrap'
import { openPopupWidget } from 'react-calendly';

import Menu from '../../components/menu'
import Footer from '../../components/footer'
import Questions from '../../components/questions'
import ContactForm from './contactForm'
import Skills from './skills'

import questions from './questions'
import './contact.css'

class Contact extends Component {

    handleCalendlyClick() {
        openPopupWidget({ 
            url: 'https://calendly.com/melenie_codes/book',
            pageSettings: {
                "backgroundColor": "ffffff",
                "hideEventTypeDetails": false,
                "hideLandingPageDetails": false,
                "primaryColor": "00a2ff",
                "textColor": "4d5055"
            },
            utm: {
                "utmSource": "Website",
            }
        });
    }

    render() {
        return (
            <div className="app">
                <Menu />
                <main className="app__main" style={{marginTop: '64px'}}>
                    <Container>
                        <Row className="contact__row">
                            <div className="contact__hero contact__image">
                                <img alt="let's work together" src='/assets/work-together.jpg' />
                                <div className="contact__hero_text">Let's work together</div>
                            </div>
                        </Row>
                        <Row className="contact__row">
                            <Skills />
                        </Row>
                        <Row className="contact__row contact__form">
                            <Col lg={{ span: 6 }}  className="contact__left">
                                <h4>Ready to work together?</h4>
                                <span>Need some extra help with your website? <br/>Have a design but don't currently have the technical skills in house?</span>
                                <Button
                                    size="lg"
                                    variant="primary" 
                                    className="contact__book"
                                    onClick={this.handleCalendlyClick}>
                                        Book a call with me
                                </Button>
                                <Card className="contact__card">
                                    <Card.Body className="contact__image">
                                        <img src='/assets/melenie_schatynski.jpg' alt="melenie schatynski"/>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={{ span: 6 }}>
                                <ContactForm />
                            </Col>
                        </Row>
                    </Container>
                    <Questions questions={questions} />
                    <Footer />
                </main>
            </div>
        )
    }
}

export default Contact