import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

import './footer.css'

const FooterContainer = styled.footer`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 16px;
    width: 100%;
    max-width: 100vw;
`

const Footer = () => {
    const elements = []

    const instagram = (
        <a href="https://instagram.com/melenie_codes" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
        </a>
    )

    const facebook = (
        <a href="https://www.facebook.com/WorkshopsByMelenie" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} />
        </a>
    )

    const linkedin = (
        <a href="https://www.linkedin.com/in/melenie" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
    )

    elements.push(instagram)
    elements.push(facebook)
    elements.push(linkedin)

    return (
        <FooterContainer className="footer" >
            <small>Melenie Schatynski ©</small>
            <div className="footer__social">
                {elements}
            </div>
        </FooterContainer>
    )
}

export default Footer