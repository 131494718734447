/* eslint-disable arrow-body-style */

const layer = ( type ) => {
	switch ( type ) {
	case 'max':
		return 'z-index: 10000;';
	case 'high':
		return 'z-index: 1000;';
	case 'mid':
		return 'z-index: 100;';
	default:
		return 'z-index: 1;';
	}
};

const media = ( max, content ) => {
	return `@media only screen and (min-width: ${max}) {
		${content}
	}`;
};

const media_by_range = ( min, max, content ) => {
	return `@media all and (min-width: ${min}) and (max-width: ${max}) {
		${content}
	}`;
};

const mixin = {
	layer,
	media,
	media_by_range,
};

export default mixin;
