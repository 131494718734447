import React from 'react';

import './home.css'

const Home = () => {
    return (
        <section>
        </section>
    );
}

export default Home