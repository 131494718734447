import React from 'react'
import { Row, Col } from 'react-bootstrap'

const Skills = () => {
    return (
        <Row className="tiles__row">
            <Col sm={{ span: 4 }} md={{ span: 3 }}>
                <span className="tiles__emoji" role="img" aria-label="person on computer emoji">👩‍💻</span>
                <p>Looking to improve your <b>website</b> and need a <b>Developer</b> to help you with some big or small changes?</p>
            </Col>
            <Col sm={{ span: 4 }} md={{ span: 3 }}>
                <span className="tiles__emoji" role="img" aria-label="calendar emoji">🤖</span>
                <p>Need help with your <b>server</b> or have a general tech question about your current setup?</p>
            </Col>
            <Col sm={{ span: 4 }} md={{ span: 3 }}>
                <span className="tiles__emoji" role="img" aria-label="books emoji">📚</span>
                <p>Need advice about creating workshops and resources for the <b>UK Coding Curriculum</b>?</p>
            </Col>
            <Col sm={{ span: 4 }} md={{ span: 3 }}>
                <span className="tiles__emoji" role="img" aria-label="experiment emoji">👩‍🏫</span>
                <p>Would like to speak to me about Personal or Team <b>Coding Workshops & Tutorials</b>?</p>
            </Col>
        </Row>
    )
}

export default Skills