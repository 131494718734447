import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

import './tiles.css'

const Tiles = () => {
    return (
        <Container className="tiles">
            <h2 className="title">What to expect</h2>
            <div className="tiles__content">
                <Row className="tiles__row">
                    <Col sm={{ span: 6 }} md={{ span: 4 }}>
                        <span className="tiles__emoji" role="img" aria-label="person on computer emoji">👩‍💻</span>
                        <p><b>Discover</b> basic programming concepts and <b>understand</b> the tools needed to write computer programs.</p>
                    </Col>
                    <Col sm={{ span: 6 }} md={{ span: 4 }}>
                        <span className="tiles__emoji" role="img" aria-label="calendar emoji">📅</span>
                        <p>Weekly activities to <b>enhance</b> and flex your new found skills during the week between each session.</p>
                    </Col>
                    <Col sm={{ span: 6 }} md={{ span: 4 }}>
                        <span className="tiles__emoji" role="img" aria-label="books emoji">📚</span>
                        <p>Learn where to <b>find relevant resources</b> to <b>quickly improve</b> your code.</p>
                    </Col>
                    <Col sm={{ span: 6 }} md={{ span: 4 }}>
                        <span className="tiles__emoji" role="img" aria-label="experiment emoji">🧪</span>
                        <p>Cohorts are limited to 10 people to keep learning <b>personal</b> and each session is made interactive with <b>dedicated experiment time</b>.</p>
                    </Col>
                    <Col sm={{ span: 6 }} md={{ span: 4 }}>
                        <span className="tiles__emoji" role="img" aria-label="ladybug emoji">🐞</span>
                        <p>Learn how to confidently <b>debug your code</b> by identifying and <b>solving issues</b> to complex problems!</p>
                    </Col>
                    <Col sm={{ span: 6 }} md={{ span: 4 }}>
                        <span className="tiles__emoji" role="img" aria-label="strong emoji">💪</span>
                        <p><b>Share your coding successes</b> and ask questions with our online community (limited to workshop attendees only).</p>
                    </Col>
                </Row>

            </div>
        </Container>
    )

}

export default Tiles