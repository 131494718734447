import React from 'react'
import { Carousel } from 'react-bootstrap'
import styled from 'styled-components'
import Quotes from '../../assets/quotes.png'

import './testimonial.css'

const Title = styled.h3`
    position: relative;
    margin-top: 50px;
    margin-bottom: 24px;
    padding: 8px 16px 4px 16px;
    z-index: 10;
    text-align: center;

    &::after {
        background-image: url(${( props ) => props.quotes});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        content: '';
        height: 50px;
        width: 100%;
        position: absolute;
        top: -45px;
        left: 0;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -8px;
        top: -8px;
        background: white;
        border-radius: 15px;
        z-index: -1;
    }
`

const BlockQuote = styled.blockquote`
`

const Testimonial = () => {
    const testimonials = [
        {
            item: `I found working with Melenie a real pleasure. She has a real can-do attitude to everything (there's no challenge too big or too small), and she is very knowledgeable about software development on AEM and beyond. She is also more than happy to explain complex, technical concepts to others in a simple way. I would happily recommend Melenie for both her expertise and experience`,
            person: 'Juliet', 
            date: 'July 2020',
            emoji: '👩🏼‍💻',
        },
        {
            item: `Working with Melenie was an absolute blast - after having no experience with Python and getting frustrated with some of the courses online; I found that Mel’s blend of teaching experience and development knowledge was the perfect balance. It allowed to work at your own pace with the support and foundation provided and the ability to ask questions and get quick detailed responses that are basic and less daunting for a newcomer. The manner in which Mel teaches provides you with knowledge attached to a memory so you never forget what you need to do! Definitely a rare breed when it comes to this art.`,
            person: 'Gazi', 
            date: 'Aug 2020',
            emoji: '👨🏽‍💻 ',
        },
        {
            item: `I just wanted to say how happy it is I took your class, things made so much more sense and I can see how what we are learning could easily apply to my work - so thank you so much!`,
            person: 'Selina', 
            date: 'Aug 2020',
            emoji: '👩🏻‍💻',
        }
    ];

    const renderCarouselItem = testimonials.map(testimonial => {
        return (
            <Carousel.Item className="testimonial__item">
                <BlockQuote>
                    {testimonial.item}
                </BlockQuote>
                <span className="testimonial__person">{testimonial.person}, {testimonial.date} {testimonial.emoji}</span>
            </Carousel.Item>
        )
    })


    return (
        <div className="testimonial">
            <Title quotes={Quotes}>What are others saying?</Title>
            <div className="testimonial__items">
                <Carousel className="testimonial__carousel" data-interval="false">
                    {renderCarouselItem}            
                </Carousel>
            </div>
        </div>
    )
}

export default Testimonial