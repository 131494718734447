import React from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'

import './hero.css';

const Container = styled.div`
    position: relative;
    // background-image: url('/assets/ide.png');
    background-repeat: repeat;
    background-size: 500px 500px;

    // &::before {
    //     content: '';
    //     position: absolute;
    //     background-color: #ffe40c;
    //     // opacity: 0.5;
    //     top: 0;
    //     bottom: 0;
    //     right: 0;
    //     left: 0;
    //     z-index: 1;
    // }
`


const Hero = (props) => {
    return (
        <Container className="hero">
            <div className="hero__image" id="preload">
                <img src='/assets/sat_on_computer.jpg' alt="Melenie"/>
            </div>
            <div className="hero__text">
                <h3>Hey! <span role="img" aria-label="waving emoji">👋 </span>I'm Melenie, </h3>
                <p>I bring ideas to life using code and teach others to do the same! <span className="hero__emoji" role="img" aria-label="lightening">⚡️</span></p>
                <div className="hero__buttons">
                    <Button 
                        href="/workshops"
                        size="lg"
                        variant="light"
                        className="hero__button"
                    >
                        Discover workshops
                    </Button>
                    <Button 
                        href="/contact"
                        size="lg"
                        variant="light"
                        className="hero__button"
                    >
                        Get in touch!
                    </Button>
                </div>
            </div>
        </Container>
    )

}

export default Hero
