export default [
    {
        title: `I want to learn to code but I don't know where to start!`,
        answer: `Check out my <a href="https://www.melenie.co.uk/workshops">workshops page</a> for more information! Then if you still have questions then please feel free to reach out to me via the Contact Form!`,
    },
    {
        title: 'I hear you can help with Web Development, which languages?',
        answer: `I'm a full stack developer but I mostly have experience with HTML, CSS, Javascript and PHP. For Javascript I'm currently using Vue and React. This means I can help with most Web Development but if you're unsure, just drop me an email!`,
    },
    {
        title: 'I use a CMS - do you have experience with one of those?',
        answer: `Yes! I've built custom themes and plugins for Wordpress and integrated websites with Contentful`,
    },
    {
        title: `I know I need a Developer but I'm not really sure why`,
        answer: `Absolutely fine! Send me a message or book me for a chat and I'm more than happy to point you in the right direction. Even if I can help you - you're under no obligation to continue with me after our initial chat!`,
    },
    {
        title: 'Are you able to design my new website too?',
        answer: `Unfortunately I'm not a Web Designer. However if you already have a design or a pretty good idea of what you want then drop me a message as I may be able to help!`,
    },
    {
        title: `I've booked a call with you, what happens next?`,
        answer: `Once we've had a quick discussion I will put together a quote for you! At this point if you choose to continue I will set up a project board (so we can share information), send over a contract for you to sign and an invoice for 50% of the payment. The remainder will be due as outlined in the contract (usually at the end of the project)`,
    },
]