import React from 'react'
import styled from 'styled-components'
import { Card, Container , Accordion } from 'react-bootstrap'
import { media } from '../../utils/variables';
import mixin from '../../utils/mixins';

import './questions.css'

const FlexRow = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;

    ${mixin.media( media.xtablet, `
        flex-direction: row;
    ` )}
`;

const Questions = (props) => {
    const faqs = props.questions.map((item, index) => {
        return (
            <Accordion key={index}>
                <Card className="">
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="question__header">
                        <span className="question__open">+</span> {item.title}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body className="question__body">
                            <span dangerouslySetInnerHTML={{__html: item.answer}} />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    })

    return (
        <Container className="question">
            <h2 className="title">Questions</h2>
            <FlexRow className="question__wrapper">
                { faqs }
            </FlexRow>
        </Container>
    )
}

export default Questions