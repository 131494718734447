import React from 'react';
import { Element } from 'react-scroll';

import Menu from './components/menu'
import Hero from './components/hero'
import Home from './pages/home'
import Footer from './components/footer'

import './App.css';

function App() {
	return (
		<div className="app">
			<Menu />
			<Element id="home" name="home">
				<Hero />
			</Element>
			<main className="app__main">
				<Home />
				<Footer />
			</main>
		</div>
	);
}

export default App;
