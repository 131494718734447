import React from 'react'
import { Row, Container } from 'react-bootstrap'
import { Link } from 'react-scroll';
import Event from './event'

import './events.css'

const Events = () => {

    const eventsData = [{
        title: 'Introduction to Coding with Python',
        description: 'Learn how to code by creating a Web Scraper and manipulating data. We will cover the basics such as variables, functions aswell as discovering how to use libraries to extend our code',
        level: 'Absolute beginner',
        where: 'Online 🌍',
        first: 'Tues 17th Nov',
        last: 'Tues 15th Dec',
        time: '6:00pm - 7:15pm BST',
        sessions: '5 x 1 hour 15 minutes',
        price: '£200',
        frequency: 'Tuesdays Weekly',
        modules: [
            'Week 1: Variables & Conditionals',
            'Week 2: Creating Functions & using pre built ones',
            'Week 3: Python Libraries & Lists',
            'Week 4: Extending Libraries with Loops',
            'Week 5: Project'
        ],
        link: 'https://www.eventbrite.co.uk/e/introduction-to-coding-with-python-5-week-course-tickets-116784237799?aff=website',
        cta: 'Enrol on the course. Starts Tues 17th Nov ⚡️'
    },{
        title: 'Coding with Python: Part 2',
        description: 'This course will continue on from the Introduction to Coding with Python course and will look deeper at the basic programming concepts to enhance our code further',
        level: 'Beginner',
        where: 'Online 🌍',
        first: 'TBA',
        time: 'TBA',
        sessions: '5 x 1 hour 15 minutes',
        frequency: 'Weekly',
        price: 'TBA',
        link: '#signup',
        modules: [],
        cta: 'Sign up to my mailing list to be notified as course details update 💌'
    }]

    const elements = eventsData.map((item, index) => {
        return (
            <Event key={index} event={item} /> 
        )
    }); 

    return (
        <Container>
            <h2 className="title">Upcoming Workshops</h2>
            <Row className="events__content">
                <Row className="events__row">
                    {elements}
                </Row>
                <Row className="events__row">
                    <Link
                        // eslint-disable-next-line react/jsx-boolean-value
                        offset={-60}
                        smooth
                        spy
                        hashSpy
                        isDynamic
                        duration={500}
                        activeClass="active"
                        className="events__link"
                        to="signup"
                    >
                        Unable to attend this time? Keep in touch and be notified when new courses are available
                    </Link>
                </Row>
            </Row>
        </Container>
    )

}

export default Events