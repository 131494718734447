export default [
    {
        title: 'What is Python?',
        answer: `Python is a programming language which can be used for web development, creating tools, AI, machine learning, mobile development and video games. It is commonly used to teach beginners because of it's simple syntax and setup`,
    },
    {
        title: 'Do I need to have any coding experience?',
        answer: 'Not for the Introduction to Coding with Python Course! This course has been designed for absolute beginners',
    },
    {
        title: 'Will I need to buy anything for the course?',
        answer: 'No, everything we will use in the course will be free to download!',
    },
    {
        title: 'Are the classes live?',
        answer: 'Yes and interactive! Every week you will join me via video link with up to 9 others (class sizes are capped to 10 people)',
    },
    {
        title: 'Will you provide resources?',
        answer: `Yes! Every week I will provide you with scripts, exercises and useful resources to read! These can all be accessed through <a href="https://wbm.melenie.co.uk/login">the course area</a> and you'll be given access a few days before the course start date`,
    },
    {
        title: 'What happens if I miss a session?',
        answer: `Don't worry! Sessions are recorded and uploaded to the course area as soon as they are available. Just watch it when you have time and contact me if you have any questions`,
    },
    {
        title: 'Is Python the right language for me?',
        answer: `Python as a language is perfect for beginners! The concepts you are learning are universal to programming and it's much easier to understand other languages once you've mastered the basics with Python! In addition, Python includes lots of third party libraries which makes extending your code much easier!`,
    },
    {
        title: 'Where can I find out more?',
        answer: 'Follow me on <a target="_blank" href="https://www.instagram.com/melenie_codes/">instagram</a> or feel free to <a href="https://www.melenie.co.uk/contact/">contact me</a> for more information!',
    }
]