import React, { Component } from 'react'
import styled from 'styled-components'
import { Card, Button, Row, Container } from 'react-bootstrap'

import Circle from '../../assets/circle.svg';

import './signup.css'

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &::after {
        // background-image: url(${( props ) => props.circle});
        background-repeat: repeat;
        background-color: #ffe40c;;
        background-size: 6px 6px;
        content: '';
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
`;

class Signup extends Component {
    componentDidMount() {
        const inputs = document.querySelectorAll('.ff__control')
        this.setTabIndexes()

        inputs.forEach(input => {

            input.addEventListener('click', () => {
                this.setTabIndexes(input)
            })

            input.addEventListener('focus', () => {
                this.setTabIndexes(input)
            })
        })
    }

    setTabIndexes() {
        const inputs = document.querySelectorAll('.ff__control')
        inputs.forEach(input => {
            input.setAttribute('tabindex', 0)
        })
    }

    render() {
        return (
            <Wrapper circle={Circle}>
                <h2 className="signup__title">Stay in touch!</h2>
                <Container className="signup">
                    <Card
                        className="signup__card">
                        <Card.Title>
                            <div className="signup__detail">Sign up to be notified for updated course details, new course dates, latest post and coding annoucements! <span role="img" aria-label="letter emoji">💌 📣</span></div>
                        </Card.Title>
    
                        <Card.Body className="signup__body">
                            <div class="fd-ef-5fa94dfb76d8d63f9fcf15a4">
                                <div class="ff__root">
                                    <div class="ff__container">
                                        <form class="ff__form" action="https://form.flodesk.com/submit" method="post" data-form="fdv2">
                                            <div class="ff__fields">
                                                <input type="text" name="name" value="" style={{display: 'none'}} />
                                                <input type="hidden" name="submitToken" value="d49512239ceef16b955aa6ae92dbf93da570782c77de5cca019198fc9390e918c21107fded1e55edf5d51c4e52b87f76a19c9f7430b30152776d4354dd0660d237aea73d0c93b2353ee36371c7680b08e6920cc4995614acb8d48151a344211d" />
                                                <div class="ff__grid">
    
                                                    <div class="ff__cell">
                                                        <div class="fd-form-group">
                                                            <input class="fd-form-control ff__control" type="text" name="firstName" placeholder="First name" tabindex="0"/>
                                                        </div>
                                                    </div>
    
                                                    <div class="ff__cell">
                                                        <div class="fd-form-group">
                                                            <input class="fd-form-control ff__control" type="text" name="lastName" placeholder="Last name" tabindex="0"/>
                                                        </div>
                                                    </div>
    
                                                    <div class="ff__cell">
                                                        <div class="fd-form-group">
                                                            <input class="fd-form-control ff__control" type="text" name="email" placeholder="Email address" tabindex="0"/>
                                                        </div>
                                                    </div>
                                                    <div class="ff__footer">
                                                        <button type="submit" class="fd-btn ff__button" data-form-el="submit" tabindex="0">
                                                            <span>Subscribe</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="fd-success ff__success" data-form-el="success">
                                                <p>You’ve successfully signed up! Check your email for details.</p>
                                            </div>
                                            <div class="fd-error ff__error" data-form-el="error"></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Container>
            </Wrapper>
        )
    }
}

export default Signup